var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.formTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _vm.formType == 2
            ? _c(
                "div",
                [
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { span: 6 } }, [_vm._v("租户：")]),
                      _c("a-col", { attrs: { span: 18 } }, [
                        _vm._v(_vm._s(_vm.tenantName)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { span: 6 } }, [
                        _vm._v("ClientId："),
                      ]),
                      _c("a-col", { attrs: { span: 18 } }, [
                        _vm._v(_vm._s(_vm.clientId)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { span: 6 } }, [
                        _vm._v("ClientCode："),
                      ]),
                      _c("a-col", { attrs: { span: 18 } }, [
                        _vm._v(_vm._s(_vm.clientCode)),
                      ]),
                    ],
                    1
                  ),
                  _c("a-divider"),
                ],
                1
              )
            : _vm._e(),
          _vm.formType == 1
            ? _c(
                "a-form-model-item",
                { attrs: { label: "所属租户", prop: "tenantId" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        "show-search": "",
                        placeholder: "请选择租户",
                        "option-filter-prop": "children",
                        "filter-option": _vm.filterOption,
                      },
                      model: {
                        value: _vm.form.tenantId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "tenantId", $$v)
                        },
                        expression: "form.tenantId",
                      },
                    },
                    _vm._l(_vm.tenantOptions, function (d, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: d.id } },
                        [_vm._v(" " + _vm._s(d.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.formType == 1
            ? _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "clientCode",
                    prop: "clientCode",
                    help: "clientCode值用于对租户client的简化标记。可选项，不填则由系统自动生成。",
                  },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入3到5位字母" },
                    model: {
                      value: _vm.form.clientCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "clientCode", $$v)
                      },
                      expression: "form.clientCode",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "每日获取Token限制次数",
                prop: "numberLimit",
                help: "每日可获取token的次数，填0为不限制",
              },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "每日获取Token限制次数" },
                model: {
                  value: _vm.form.numberLimit,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "numberLimit", $$v)
                  },
                  expression: "form.numberLimit",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "ip白名單(多个ip以半角逗号隔开)",
                prop: "whiteList",
                help: "IP白名单最多可填两个IP地址",
              },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入ip白名單(多个ip以半角逗号隔开)" },
                model: {
                  value: _vm.form.whiteList,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "whiteList", $$v)
                  },
                  expression: "form.whiteList",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }