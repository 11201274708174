<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="formTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <!-- <a-form-model-item label="所属系统用户id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入所属系统用户id" />
      </a-form-model-item> -->
      <!-- <a-form-model-item label="所属租户" prop="tenantId" >
        <a-input v-model="form.tenantId" placeholder="请输入租户" />
      </a-form-model-item> -->
      <div v-if="formType == 2">
        <a-row>
          <a-col :span="6">租户：</a-col>
          <a-col :span="18">{{ tenantName }}</a-col>
        </a-row>
        <a-row>
          <a-col :span="6">ClientId：</a-col>
          <a-col :span="18">{{ clientId }}</a-col>
        </a-row>
        <a-row>
          <a-col :span="6">ClientCode：</a-col>
          <a-col :span="18">{{ clientCode }}</a-col>
        </a-row>
        <a-divider />
      </div>
      <a-form-model-item label="所属租户" prop="tenantId" v-if="formType == 1">
        <a-select
          show-search
          v-model="form.tenantId"
          placeholder="请选择租户"
          option-filter-prop="children"
          :filter-option="filterOption"
        >
          <a-select-option v-for="(d, index) in tenantOptions" :key="index" :value="d.id">
            {{ d.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="clientCode" prop="clientCode" v-if="formType == 1" help="clientCode值用于对租户client的简化标记。可选项，不填则由系统自动生成。">
        <a-input v-model="form.clientCode" placeholder="请输入3到5位字母" />
      </a-form-model-item>
      <a-form-model-item label="每日获取Token限制次数" prop="numberLimit" help="每日可获取token的次数，填0为不限制">
        <a-input v-model="form.numberLimit" placeholder="每日获取Token限制次数" />
      </a-form-model-item>
      <a-form-model-item label="ip白名單(多个ip以半角逗号隔开)" prop="whiteList" help="IP白名单最多可填两个IP地址">
        <a-input v-model="form.whiteList" placeholder="请输入ip白名單(多个ip以半角逗号隔开)" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getClient, addClient, updateClient } from '@/api/openapi/client'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      loading: false,
      formTitle: '',
      tenantName: '',
      clientId: '',
      clientCode: '',
      // 表单参数
      form: {
        id: null,
        userId: null,
        numberLimit: null,
        whiteList: null,
        tenantId: null
      },
      tenantOptions: [],
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        numberLimit: [
          { required: true, message: '接口访问限制次数：每天訪問獲取token的次數限制不能为空', trigger: 'blur' }
        ],
        tenantId: [{ required: true, message: '所属租户ID不能为空', trigger: 'blur' }]
      }
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        numberLimit: null,
        whiteList: null,
        tenantId: null,
        clientCode: null
      }
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      // this.open = true
      // this.formTitle = '添加'
      getClient().then(response => {
        this.tenantOptions = response.tenants
        this.open = true
        this.formTitle = '新增Client信息'
      })
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const ID = row ? row.id : ids
      this.tenantName = row.tenantName
      this.clientId = row.clientId
      this.clientCode = row.clientCode
      getClient(ID).then(response => {
        this.form = response.data
        this.tenantOptions = response.tenants
        this.open = true
        this.formTitle = '修改Client信息'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      const that = this
      this.$refs.form.validate(valid => {
        if (valid) {
          if (that.form.id !== undefined && that.form.id !== null) {
            updateClient(that.form).then(response => {
              that.$message.success('修改成功', 3)
              that.open = false
              that.$emit('ok')
            })
          } else {
            addClient(this.form).then(response => {
              // this.$message.success('新增成功', 3)
              const h = this.$createElement
              this.$success({
                  title: '新增成功,请保存好以下Client信息：',
                  width: 750,
                  content: h('div', {}, [
                    h('p', {}, [
                      h('b', 'clientId：'),
                      h('span', response.data.clientId)
                    ]),
                    h('p', {}, [
                      h('b', 'clientSecret：'),
                      h('span', response.data.clientSecret)
                    ])
                  ]),
                  onOk() {}
              })
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
